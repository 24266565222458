import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import cogoToast from 'cogo-toast';

const INITIAL_STATE = {
	error: null,
	loading: true,
	authUser: null,
	cart: []
};

const shippingOptionsObject = {
	'shippingStandard': 'Standard Shipping',
	'shippingExpedited': 'Expedited Shipping',
	'shippingSameDayDelivery': 'Same Day Delivery',
	'Free shipping': 'Free shipping',
	'Local pickup': 'Local pickup'
}

class Cart extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}
	
	componentDidMount() {
		document.title = 'Cart';
		
		const { authUser, cart } = this.state;
		const self = this;
		
		if(authUser && cart){
			/* This will also be triggered when new items are added to or removed from cart  */
			self.unsubscribeCartItems = self.props.firebase.cartItems().doc(authUser.uid).onSnapshot(snapshot => {
				let updatedCart = []; /* Set empty array cart by default */
				
				if(snapshot.data() && Object.keys(snapshot.data()).length){
					/* Loop through list of cart item IDs  */
					Object.values(snapshot.data()).forEach(function (snapshotCart, index) {
						/* Query the items based on item ID */
						self.unsubscribeProduct = self.props.firebase.product(snapshotCart.id).onSnapshot(snapshotProduct => {
							/* If we receive a change on details of our product, we first check if it exists in our current cart state */
							let is_existing = updatedCart.find(item => snapshotProduct.id === item.id);
							if(is_existing){
								/* If existing, we find the position (index) of this item in our current cart state */
								let itemIndex = updatedCart.findIndex(item => snapshotProduct.id === item.id);
								/* Then we remove the item from the current cart state because we will append the updated item in our next code bellow (updatedCart.push())*/
								updatedCart.splice(itemIndex, 1); 
							}
							
							/* Append to cart array to reload entire cart item list */
							updatedCart.push({...snapshotCart, ...snapshotProduct.data()});
							self.setState({cart: updatedCart, loading: false}); /* !!!! setState is running multiple times here, figure out how to detect when child_added completed*/
						});
					});
				} else {
					self.setState({cart: [], loading: false})
				}
			});
			
		} else {
			self.setState({ loading: false });
		}
	}
	
	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {
		this.unsubscribeCartItems();
		this.unsubscribeProduct && this.unsubscribeProduct();
	}
	
	onRemoveFromCart = id => {
		if(window.confirm('Are you sure you want to delete this product from your cart?')){
			const { authUser, cart } = this.state;
			
			let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item exists in cart from state */
			if(is_existing){
				let cartRef = this.props.firebase.cartItems();
				
				/* Get current cart contents */
				cartRef.doc(authUser.uid).get().then(snapshot => {
					let updatedCart = Object.values(snapshot.data()); /* Clone it first */
					let itemIndex = updatedCart.findIndex(item => id === item.id); /* Get the index of the item we want to delete */
					
					/* Remove item from the cloned cart state */
					updatedCart.splice(itemIndex, 1); 

					/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
					cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
						cogoToast.success("Removed from cart");
					});
				});
			}
		}
	}
	
	onChangeQty = (event, id) => {
		event.persist(); /* Keep the original synthetic event around */
		
		const { authUser, cart } = this.state;
		
		let product = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item exists in cart from state */
		
		if(product){
			if(event.target.value > product.stock){
				/* Do not allow save if user is trying to checkout more than what is available on stock */
				event.preventDefault();
				cogoToast.error("Insuficient stock");
				
			} else if(event.target.value <= 0){
				/* Do not allow save if user is trying to change to an invalid value */
				event.preventDefault();
				cogoToast.error("Invalid value, must be a positive number");
				
			} else {
				let cartRef = this.props.firebase.cartItems();
				
				/* Get current cart contents */
				cartRef.doc(authUser.uid).get().then(snapshot => {
					let updatedCart = Object.values(snapshot.data()); /* Clone it first */
					let itemIndex = updatedCart.findIndex(item => id === item.id); /* Get the index of the item we want to delete */
					
					/* Set item quantity */
					updatedCart[itemIndex]['quantity'] = event.target.value; 
					
					/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
					cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
						cogoToast.success("Quantity updated");
					});
				});
			}
		}
	}
	
	onChangeShippingOption = (event, id) => {
		event.persist(); /* Keep the original synthetic event around */
		
		/* Do not allow add to cart if option selected is not in list of available shipping options */
		if(event.target.value in shippingOptionsObject){
																		
			const { authUser, cart } = this.state;
			
			let productObject = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item exists in cart from state */
			if(productObject){
				let cartRef = this.props.firebase.cartItems();
				
				/* Get current cart contents */
				cartRef.doc(authUser.uid).get().then(snapshot => {
					let updatedCart = Object.values(snapshot.data()); /* Clone it first */
					let itemIndex = updatedCart.findIndex(item => id === item.id); /* Get the index of the item we want to delete */
					
					/* Update item shipping type and value */
					updatedCart[itemIndex]['shippingType'] = event.target.value; 
					updatedCart[itemIndex]['shippingValue'] = productObject[event.target.value]; 
					
					/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
					cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
						cogoToast.success("Shipping option updated");
					});
				});
			}
		}
	}
	
	render() {
		const { error, loading, cart, settings } = this.state;
			
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{loading ? <div>Loading...</div> : 
					<React.Fragment>
						<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
							<span className="navbar-brand">Cart</span>
						</nav>

						<div className="container-fluid py-3">
							<div className="mb-3">
								<div className="table-responsive">
									<table className="table res-tbl">
										<thead>
											<tr>
												<th>Product</th>
												<th>Quantity</th>
												<th>Shipping</th>
												<th>Total</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{cart.length ? cart.map((product, index) => (
												<tr key={index}>
													<td data-label="Product">
														<Link to={{pathname: '/products/view/' + product.id}} className="d-block d-lg-flex" style={{maxWidth: '300px'}}>
															<div className="mr-3 mb-2 mb-lg-0">
																<img src={`${product.featured_image ? product.featured_image : '/placeholder.jpg'}`} width="80" alt={product.name} />
															</div>
															<div className="d-inline-block">{product.name}</div>
														</Link>
													</td>
													<td data-label="Quantity">
														{product.stock > 0 
															?
																<div>
																	<input onChange={(event) => this.onChangeQty(event, product.id)} type="number" min="1" max={product.stock} defaultValue={product.quantity} className="form-control d-xl-inline-block d-block mr-2 mb-2 mb-xl-0" style={{width: "100px"}} />
																	<span className="text-success f-13">{product.stock} available</span>
																</div>
															: 
																<span className="text-danger f-13">Out of stock</span>
														}
													</td>
													<td data-label="Shipping">
														{product.shippingMethod === 'Shipping options' 
															?
																<select onChange={(event) => this.onChangeShippingOption(event, product.id)} defaultValue={product.shippingType} className="form-control" style={{maxWidth: '220px'}}>
																	{product.shippingStandard && 
																		<option value="shippingStandard">
																			+{settings && settings.currencySymbol} {product.shippingStandard * product.quantity} on {shippingOptionsObject['shippingStandard']}
																		</option>
																	}
																	{product.shippingExpedited && 
																		<option value="shippingExpedited">
																			+{settings && settings.currencySymbol} {product.shippingExpedited * product.quantity} on {shippingOptionsObject['shippingExpedited']}
																		</option>
																	}
																	{product.shippingSameDayDelivery && 
																		<option value="shippingSameDayDelivery">
																			+{settings && settings.currencySymbol} {product.shippingSameDayDelivery * product.quantity} on {shippingOptionsObject['shippingSameDayDelivery']}
																		</option>
																	}
																</select>
															: 
																<em>{product.shippingMethod}</em>
														}
													</td>
													<td data-label="Total">{settings && settings.currencySymbol} {(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}</td>
													<td data-label="Action"><button onClick={() => this.onRemoveFromCart(product.id)} className="btn btn-dark">Remove</button></td>
												</tr>
											)) : 
												<tr>
													<td>Cart is empty</td>
												</tr>
											}
										</tbody>
									</table>
									<table className="table res-tbl">
										<tbody>	
											<tr>
												<td className="align-middle font-weight-bold d-none d-sm-block">Cart Total</td>
												<td data-label="Cart Total" className="font-weight-bold">
													{settings && settings.currencySymbol + ' '} 
													{cart.length ? cart.reduce((sum, i) => (
														sum += (i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue)
													), 0).toFixed(2) : 0}
												</td>
												<td></td>
												<td>
													{cart.length > 0 &&
														<Link to="/checkout" className="btn btn-purple rounded-0 mr-2 px-4 py-2 font-weight-bold mb-2">Proceed to checkout</Link>
													}
													<Link to="/" className="btn btn-purple rounded-0 px-4 py-2 font-weight-bold mb-2">Continue Shopping</Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				}
				
				{error && <div>{error.message}</div>}
						
			</div>
		);
	}
}

const condition = authUser => !!authUser; // "!!authUser" is shorthand for: "authUser != null"

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(Cart));
   we just organize it like bellow:
*/
const CartPage = compose(
	withAuthorization(condition),
	withRouter,
	withFirebase,
)(Cart);

export default CartPage;