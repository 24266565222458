import React, { Component } from "react";
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import cogoToast from 'cogo-toast';

const INITIAL_STATE = {
	order: null,
	loading: true,
	authUser: null,
	error: null
};

class SellerOrdersView extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}
	
	componentDidMount() {
		const { authUser } = this.state;
		const self = this;
		
		let order_id = this.props.match.params.id;
		
		if(order_id){
			document.title = 'Order #' + order_id;
			
			/* Fetch product from our Firebase realtime database  */
			/* self.props.firebase.product() -- the "product" is taken from firebase.js  */
			self.props.firebase.order(order_id).get().then(snapshot => { /* Use get() only call it once (disable realtime) */
				const orderObject = snapshot.data();
				
				if(orderObject){
					let orderItems = [];
					
					orderObject.cart.forEach(snapshotCart => {
						self.props.firebase.product(snapshotCart.id).get().then(snapshotProduct => {
							orderItems.push({...snapshotCart, ...snapshotProduct.data()});
							self.setState({orderItems: orderItems});
						});
					});
					
					/* Others contains information about per seller actions such as status of order or notes about the order. */
					let orderOthersObject = orderObject.others.find(item => authUser.uid === item.sellerID);
					
					/* Check if current user owns the order. */
					if(orderOthersObject){
						self.setState({
							order: orderObject,
							others: orderOthersObject,
							oid: order_id, 
							loading: false
						});
					} else {
						self.props.history.push(ROUTES.NOT_FOUND);
					}
					
				} else {
					self.props.history.push(ROUTES.NOT_FOUND);
				}
			});
		}
	}
	
	onUpdate = event => {
		const { order, oid } = this.state;
		
		this.props.firebase.order(oid).update({ ...order }).then(() => {
			cogoToast.success("Order saved");
		})
		.catch(error => {
			this.setState({ error });
		});
		
	}
	
	onChange = event => {
		const { order, authUser } = this.state;
		
		let updatedOrder = order; /* Clone it first. */
		let sellerIndex = updatedOrder.others.findIndex(item => authUser.uid === item.sellerID); /* Find index position of seller in the array */
		
		updatedOrder['others'][sellerIndex][event.target.name] = event.target.value; /* Set new value. */
		
		/* Update the state */
		this.setState({ updatedOrder });
	}
	
	render() {
		const { order, orderItems, others, oid, loading, authUser, settings, error } = this.state;
		
		const shippingOptionsObject = {
			'shippingStandard': 'Standard Shipping',
			'shippingExpedited': 'Expedited Shipping',
			'shippingSameDayDelivery': 'Same Day Delivery',
		}
		
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{loading ? <div>Loading...</div> : 
					<div>
						<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
							<span className="navbar-brand">Order Details</span>
						</nav>
						
						<div className="container-fluid py-3">
							<div className="card mb-3">
								<div className="card-body">
									{error && <div className="alert alert-danger">Error: {error.message}</div>}
									
									{order.isCancelled &&
										<div className="alert alert-danger" role="alert">
											<h5 className="alert-heading">Order Cancelled</h5>
											<p className="mb-0">This order has been cancelled by the customer, please do not ship out / send the items.</p>
										</div>
									}
									
									<h5>Order <span className="text-success">{oid}</span> details</h5>
									<h6 className="text-secondary">Payment via <strong>{order.paymentOption}</strong></h6>
									<hr />
									
									<div className="row">
										<div className="col-sm-6">
											<div className="form-group">
												<label className="mb-0">Date Created:</label>
												<div className="font-weight-bold">{new Date(order.createdAt).toLocaleDateString('en-US')} {new Date(order.createdAt).toLocaleTimeString()}</div>
											</div>
											
											<div className="form-group mt-4">
												<label className="mb-0">Full Name:</label>
												<div className="font-weight-bold">{order.billing.firstName} {order.billing.lastName}</div>
											</div>
											
											<div className="form-group mt-4">
												<label className="mb-0">Contact Number:</label>
												<div className="font-weight-bold">{order.billing.phone}</div>
											</div>
											
											<div className="form-group mt-4">
												<label className="mb-0">Billing Address:</label>
												<ul>
													<li>Address: <strong>{order.billing.address}</strong></li>
													<li>City: <strong>{order.billing.city}</strong></li>
													<li>Zip: <strong>{order.billing.zip}</strong></li>
													<li>State: <strong>{order.billing.state}</strong></li>
													<li>Country: <strong>{order.billing.country}</strong></li>
												</ul>
											</div>
											
											<div className="form-group mt-4">
												<label className="mb-0">Message from buyer:</label>
												<div className="font-weight-bold">{order.customerNotes}</div>
											</div>
										</div>
										
										<div className="col-sm-6">
											{!order.isCancelled &&
												<React.Fragment>
													<div className="form-group mt-4">
														<div className="alert alert-warning mb-2 w-50">
															Customers can cancel the order if Status is set to "<strong>Pending</strong>" or "<strong>On Hold</strong>"
														</div>
														<label>Status:</label>
														<select className="form-control w-50 font-weight-bold" name="status" onChange={this.onChange} value={others.status}>
															<option value="Cancelled">Cancelled</option>
															<option value="Pending">Pending</option>
															<option value="Completed">Completed</option>
															<option value="Processing">Processing</option>
															<option value="On hold">On hold</option>
															<option value="Refunded">Refunded</option>
															<option value="Failed">Failed</option>
														</select>
													</div>
													
													<div className="form-group mt-4">
														<label>Seller Notes (only you can see this):</label>
														<textarea className="form-control w-50" rows="5" name="notes" onChange={this.onChange} value={others.notes}></textarea>
													</div>
													
													<button className="btn btn-dark" onClick={this.onUpdate}>Update</button>
												</React.Fragment>
											}
										</div>
									</div>
									
									<div className="table-responsive mt-4">
										<label>Items:</label>
										<table className="table table-sm res-tbl">
											<thead>
												<tr>
													<th>Product</th>
													<th>Cost</th>
													<th>Qty</th>
													<th>Shipping</th>
													<th>Total</th>
												</tr>
											</thead>
											<tbody>
												{orderItems && Object.keys(orderItems).length ? orderItems.map(item => (
													<tr key={item.id}>
														{item.sellerID === authUser.uid && 
															<React.Fragment>
																<td data-label="Product">
																	<Link to={{pathname: '/products/view/' + item.id}} className="d-inline-block" style={{maxWidth: '300px'}}>
																		<div className="d-block d-lg-flex">
																			<div className="float-none float-lg-left mr-3 mb-2 mb-lg-0">
																				<img src={`${item.featured_image ? item.featured_image : '/placeholder.jpg'}`} width="50" alt={item.name} />
																			</div>
																			<div className="d-flex align-self-center">{item.name}</div>
																		</div>
																	</Link>
																</td>
																<td data-label="Cost">{settings && settings.currencySymbol} {item.purchasePrice}</td>
																<td data-label="Qty">{item.quantity}</td>
																<td data-label="Shipping"> data-label=""
																	{item.shippingType in shippingOptionsObject
																		? <span>{settings && settings.currencySymbol} {item.shippingValue * item.quantity} on {shippingOptionsObject[item.shippingType]}</span>
																		: item.shippingType
																	}
																</td>
																<td data-label="Total">{settings && settings.currencySymbol} {(item.purchasePrice * item.quantity) + (item.shippingValue * item.quantity)}</td>
															</React.Fragment>
														}
													</tr>
												)) : <tr>
														<td>No orders available</td>
													</tr>
												}
											</tbody>
										</table>
									</div>
									
									<hr />
									
									<div className="form-group mt-4">
										<label>Order Total:</label>
										<h5 className="text-success">
											{settings && settings.currencySymbol} 
											{orderItems && Object.keys(orderItems).length ? orderItems.reduce((sum, i) => (
												sum += i.sellerID === authUser.uid ? (i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue): 0
											), 0).toFixed(2) : 0}
										</h5>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

export default compose(
	withAuthorization(condition),
	withFirebase,
)(SellerOrdersView);