import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import cogoToast from 'cogo-toast';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isPriceKey, getKeywords } from '../../helpers/global';

const INITIAL_STATE = {
	name: '',
	description: '',
	excerpt: '',
	category: '',
	price: '',
	salePrice: '',
	sku: '',
	stock: '',
	shippingMethod: 'Free shipping',
	shippingStandard: '',
	shippingExpedited: '',
	shippingSameDayDelivery: '',
	error: null,
};

class CreateProduct extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}
	
	componentDidMount() {
		document.title = 'New Product';
	}
	
	onSubmit = event => {
		const self = this;
		const { authUser, name, description, excerpt, category, price, salePrice, sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery } = this.state;
		const productsRef = self.props.firebase.products().doc();
		
		productsRef.set({
			name,
			keywords: getKeywords(name),
			description,
			excerpt,
			category,
			price,
			salePrice: salePrice ? parseFloat(salePrice) : 0, 
			sku, 
			stock: parseInt(stock),
			shippingMethod,
			shippingStandard: shippingStandard ? parseFloat(shippingStandard) : 0, 
			shippingExpedited: shippingExpedited ? parseFloat(shippingExpedited) : 0, 
			shippingSameDayDelivery: shippingSameDayDelivery ? parseFloat(shippingSameDayDelivery) : 0, 
			author: authUser.uid,
			createdAt: new Date().getTime()
		})
		.then(() => {
			self.setState({ ...INITIAL_STATE });
			self.props.history.push(ROUTES.PRODUCTS + '/update/' + productsRef.id);
			cogoToast.success("Product saved, you may continue editing.");
		})
		.catch(error => {
			this.setState({ error });
		});

		event.preventDefault();
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
		
		/* Empty the value of shipping options if not selected */
		if(event.target.name === 'shippingMethod' && event.target.value !== 'Shipping options'){
			this.setState({shippingStandard: '', shippingExpedited: '', shippingSameDayDelivery: ''});
		}
	};

	onChangeCheckbox = event => {
		this.setState({ [event.target.name]: event.target.checked });
	};
  
	render() {
		const { name, description, excerpt, category, price, salePrice, sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery, error, settings } = this.state;
		const isInvalid = name === '' || description === '' || excerpt === '' || category === '' || price === '' || stock === '';
		const isIncompleteShipping = shippingMethod === 'Shipping options' && shippingStandard === '' && shippingExpedited === '' && shippingSameDayDelivery === '';
		
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">New</span>
				</nav>
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<label>Name <span className="text-danger">*</span></label>
									<input name="name" value={name} onChange={this.onChange} type="text" className="form-control w-50" />
								</div>
								<div className="form-group">
									<label>Description <span className="text-danger">*</span></label>
									<CKEditor
										editor={ ClassicEditor }
										data={description}
										config={ {
											toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
										} }
									/>
								</div>
								<div className="form-group">
									<label>Short Description <span className="text-danger">*</span></label>
									<textarea rows="2" onChange={this.onChange} name="excerpt" value={excerpt} className="form-control"></textarea>
								</div>
								
								<div className="form-group">
									<label>Category <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="category" onChange={this.onChange} value={category}>
										<option value="">Select Category</option>
										{settings.productCategories && settings.productCategories.map((cat, index) => (
											<option key={index} value={cat.slag}>{cat.name}</option>
										))}
									</select>
									{!settings.productCategories && 
										<div className="alert alert-danger my-3 w-50" role="alert">
											<strong>Warning:</strong> There are currently no categories available, please contact your administrator and request to add new categories.
										</div>
									}
								</div>
										
								<label>Regular Price <span className="text-danger">*</span></label>
								<div className="input-group mb-3 w-25">
									<div className="input-group-prepend">
										<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
									</div>
									<input name="price" value={price} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
								</div>
								
								<label>Sale Price (optional)</label>
								<div className="input-group mb-3 w-25">
									<div className="input-group-prepend">
										<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
									</div>
									<input name="salePrice" value={salePrice} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" placeholder="Leave this blank if not on sale" />
								</div>
								<div className="form-group">
									<label>SKU (optional)</label>
									<input name="sku" value={sku} onChange={this.onChange} type="text" className="form-control w-25" />
								</div>
								<div className="form-group">
									<label>Stock <span className="text-danger">*</span></label>
									<input name="stock" value={stock} onChange={this.onChange} type="number" min="0" className="form-control w-25" />
								</div>
								<div className="form-group">
									<label>Shipping Method (per item) <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="shippingMethod" onChange={this.onChange} value={shippingMethod}>
										<option value="Free shipping">Free shipping</option>
										<option value="Shipping options">Shipping options</option>
										<option value="Local pickup">Local pickup</option>
									</select>
									
								</div>
								
								{shippingMethod === 'Shipping options' &&
									<div>
										{isIncompleteShipping &&
											<em className="text-danger d-block mb-2 ml-lg-4">Enter a cost in at least one of the shipping options bellow:</em>
										}
										<ul>
											<li>
												<label>Standard shipping cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingStandard" value={shippingStandard} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Expedited shipping cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingExpedited" value={shippingExpedited} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Same day delivery cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingSameDayDelivery" value={shippingSameDayDelivery} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
										</ul>
									</div>
								}
								
								<div className="mt-5">
									<button disabled={isInvalid || isIncompleteShipping} type="submit" className="btn btn-dark">Create Product</button> {isInvalid && <em className="ml-2 text-danger">Please fill up all required fields</em>}
								</div>
								
								{error && <div className="alert alert-warning">{error.message}</div>}
							</form>
						</div>
					</div>
				</div>
				
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(CreateProduct));
   we just organize it like bellow:
*/
const CreateProductPage = compose(
	withAuthorization(condition),
	withRouter,
	withFirebase,
)(CreateProduct);

export default CreateProductPage;