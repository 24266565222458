import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import cogoToast from 'cogo-toast';
import * as ROLES from '../../../constants/roles';
import AdminSettingsNavigation from './navigation';

const INITIAL_STATE = {
	error: null,
	submitted: false,
	settings: null,
	upload_logo: ""
};

const currencies = require('../../../helpers/currencies.json');

class AdminSettingsGeneral extends Component {
	constructor(props) {
		super(props);
		let settingsStorage = localStorage.getItem('settings');
		this.state = { 
			...INITIAL_STATE, 
			settings: settingsStorage ? JSON.parse(settingsStorage): {} 
		};
	}
	
	onSubmit = event => {
		event.preventDefault();
		
		const { settings, upload_logo } = this.state;
		let storageRef = this.props.firebase.storage;
		
		/* Upload images */
		if(upload_logo.length){
			/* Delete previous logo if existing */
			if(settings && settings.siteLogoPath){
				let imagetRef = storageRef.ref().child(`${settings.siteLogoPath}`);
				imagetRef.delete();
			}

			/* Upload new logo */
			let file = upload_logo[0];
			let path = `images/logo/${Math.random().toString(36).substring(7)}`;
			storageRef
				.ref(path)
				.put(file).then((snapshot) => {
					snapshot.ref.getDownloadURL().then((url) => {
						/* Apply the modification to the item directly without changing the current item index. */
						this.setState(prevState => ({
							settings: {
								...prevState.settings,
								siteLogoUrl: url,
								siteLogoPath: path,
							}
						}), () => {
							localStorage.setItem('settings', JSON.stringify(this.state.settings)); /* Update settings from localStorage */
							this.props.firebase.settings().doc('general').set({...this.state.settings}, {merge: true})
						});
					})
				})
				.catch(error => {
					this.setState({ error });
				});
		}
		this.setState({upload_logo: ""});

		/* Update user data in firebase */
		this.props.firebase.settings().doc('general').set({...settings}, {merge: true})
		.then(() => {
			localStorage.setItem('settings', JSON.stringify(settings)); /* Update settings from localStorage */
			cogoToast.success("Settings updated");
			this.setState({ submitted: true });

		}).catch(error => {
			this.setState({ error });
		});
		
	};
	
	onChange = event => {
		event.persist(); /* Keep the original synthetic event around */
		
		if(event.target.name === 'currencyCode'){
			let currency = Object.values(currencies).find(c => event.target.value === c.code);
			if(currency){
				this.setState(prevState => ({
					settings: {
						...prevState.settings,
						'currencySymbol': currency.symbol,
						[event.target.name]: event.target.value
					}
				}));
			} else {
				cogoToast.error("Invalid currency");
			}
			
		} else {
			/* Update other settings state */
			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					[event.target.name]: event.target.value
				}
			}));
		}
		
	};
	
	onChangeInputFile(event) {
		const upload_logo = Array.from(event.target.files);
		this.setState({ upload_logo });   
	};

	render() {
		const { settings, error, submitted } = this.state;
		
		const isInvalid = (settings && settings.currencySymbol) === '';
					  
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">Admin</span>
				</nav>
				
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<AdminSettingsNavigation />
							
							<h4>General Settings</h4>
							<hr />

							<form>
								<div className="form-group">
									<label>Site Name</label>
									<input
										name="siteName"
										defaultValue={settings && settings.siteName}
										onChange={this.onChange}
										type="text"
										className="form-control w-25"
									/>
								</div>
								<div className="form-group">
									<label className="mr-2">Site Logo <span className="text-danger">*</span></label>
									<label className="btn btn-purple">
										Change Logo <input id="file" type="file" onChange={this.onChangeInputFile.bind(this)} className="d-none" multiple />
									</label>

									{settings && settings.siteLogoUrl 
										? <div><img src={settings.siteLogoUrl} alt="Logo" height="150" /></div>
										: <p className="alert alert-danger w-25">No image</p>
									}
								</div>
								<div className="form-group">
									<label>Currency <span className="text-danger">*</span></label>
									<select name="currencyCode" className="form-control w-25" onChange={this.onChange} defaultValue={settings && settings.currencyCode}>
										<option value="">Select currency</option>
										{currencies && Object.values(currencies).map((currency, index) => (
											<option key={currency.code} value={currency.code} id={currency.symbol}>{currency.name} ({currency.code}) ({currency.symbol})</option>
										))}
									</select>
								</div>
								
								<button disabled={isInvalid} type="submit" className="btn btn-dark" onClick={this.onSubmit}>
									Update Settings
								</button>
								
								{error && <div className="alert alert-warning mt-3 w-50" role="alert">{error.message}</div>}
								
								{submitted &&
									<div className="alert alert-warning alert-dismissible fade show mt-3 w-50" role="alert">
										<strong>Warning:</strong> Please refresh this page in order for the new settings to take effect. 
										<button type="button" className="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.ADMIN);
	
/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(AccountBasicFormBase));
   we just organize it like bellow:
*/
const AdminSettingsGeneralForm = compose(
	withAuthorization(condition),
	withFirebase,
)(AdminSettingsGeneral);

export default AdminSettingsGeneralForm;