import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import cogoToast from 'cogo-toast';
import * as ROLES from '../../../constants/roles';
import AdminSettingsNavigation from './navigation';
import { noSpace } from '../../../helpers/global';

const INITIAL_STATE = {
	error: null,
	settings: null,
	categoryName: '',
	categorySlag: '',
};

class AdminSettingsGeneral extends Component {
	constructor(props) {
		super(props);
		let settingsStorage = localStorage.getItem('settings');
		this.state = { 
			...INITIAL_STATE, 
			settings: settingsStorage ? JSON.parse(settingsStorage): {} 
		};
	}
	
	componentDidMount() {
		document.title = 'Admin - Product Settings';
	}
	
	updateSettings(settings){
			
		/* Update user data in firebase */
		this.props.firebase.settings().doc('general').set({...settings}, {merge: true})
		.then(() => {
			localStorage.setItem('settings', JSON.stringify(settings)); /* Update settings from localStorage */
			
			this.setState({settings: settings});
			
			cogoToast.success("Updated");
			
		}).catch(error => {
			this.setState({ error });
		});
	}
	
	onSubmit = event => {
		event.preventDefault();
		const { settings } = this.state;
		
		/* Set updated settings state */
		this.updateSettings(settings);
	};
	
	onSubmitCategory = event => {
		event.preventDefault();
		
		const { settings, categoryName, categorySlag } = this.state;
		
		let newItem = {name: categoryName, slag: categorySlag};
		let updatedSettings = settings;
		
		updatedSettings.productCategories = settings.productCategories ? settings.productCategories: [];
		
		updatedSettings.productCategories.push(newItem)
		
		/* Set updated settings state */
		this.updateSettings(updatedSettings);
			
		/* Clear the category inputs */
		this.setState({categoryName: '', categorySlag: ''});
		
			
	};
	
	onDeleteCategory = (event, index) => {
		event.preventDefault();
		
		if(window.confirm('Are you sure you want to delete this category?')){
			const { settings } = this.state;
			
			let updatedSettings = settings;
			
			/* Remove item from the cloned cart state */
			updatedSettings.productCategories.splice(index, 1); 
			
			/* Set updated settings state */
			this.updateSettings(updatedSettings);
		}
	}

	onChange = event => {
		event.persist(); /* Keep the original synthetic event around */
		
		if(event.target.name === 'categoryName' || event.target.name === 'categorySlag'){
			/* Update category name and slag */
			this.setState({
				[event.target.name]: event.target.value
			});

		} else {
			/* Update other settings state */
			this.setState(prevState => ({
				settings: {
					...prevState.settings,
					[event.target.name]: event.target.value
				}
			}));
		}
		
		
	};
  
	render() {
		const { settings, error, categoryName, categorySlag } = this.state;
		
		const isInvalid =
			(settings && settings.perPageCollections) === '' ||
			(settings && settings.perPageSellerProducts) === '';
			
		const isInvalidCategory = categoryName === '' || categorySlag === '';
		  
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">Admin</span>
				</nav>
				
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<AdminSettingsNavigation />
							
							<h4>Product Settings</h4>
							<hr />
							
							<div className="form-group mb-3">
								<label className="font-weight-bold">Number of items to load on pagination:</label>
							</div>
							<div className="pl-4 mb-5">
								<div className="form-group mb-2">
									<label className="mb-1">Collections page <span className="text-danger">*</span></label>
									<input
										name="perPageCollections"
										defaultValue={settings && settings.perPageCollections}
										onChange={this.onChange}
										type="text"
										className="form-control w-25"
									/>
								</div>
								<div className="form-group mb-2">
									<label className="mb-1">Seller Products page <span className="text-danger">*</span></label>
									<input
										name="perPageSellerProducts"
										defaultValue={settings && settings.perPageSellerProducts}
										onChange={this.onChange}
										type="text"
										className="form-control w-25"
									/>
								</div>
							</div>
							
							{/* Product Categories */}
							<form>
								<div className="form-group mb-3">
									<label className="font-weight-bold">Manage Product Categories:</label>
								</div>
								<div className="pl-4 mb-5">
									<div className="form-group mb-2">
										<label className="mb-1">Category Name <span className="text-danger">*</span></label>
										<input
											name="categoryName"
											value={categoryName}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>	
									<div className="form-group mb-2">
										<label className="mb-1">Category Slag (no spaces) <span className="text-danger">*</span></label>
										<input
											name="categorySlag"
											value={categorySlag}
											onKeyPress={(event) => noSpace(event)}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>
								
									<button disabled={isInvalidCategory} type="submit" className="btn btn-dark" onClick={this.onSubmitCategory}>
										Add Category
									</button>
									
									<div className="border p-3 mt-3">
										{settings.productCategories 
											?
												<div>
													{settings.productCategories.map((category, index) => (
														<span className="py-2 px-3 mr-1 mb-1 bg-purple d-inline-block" key={index}>
															{category.name} 
															<Link to="#" className="badge badge-light ml-2" onClick={(event) => this.onDeleteCategory(event, index)}>
																<i className="material-icons align-middle font-weight-bold" style={{fontSize: '10px'}}>close</i>
															</Link>
														</span>
													))}
												</div>
											:
												<div className="text-danger">No categories available</div>
										}
									</div>
								</div>
							</form>
							
							<hr />
							
							<button disabled={isInvalid} type="submit" className="btn btn-dark" onClick={this.onSubmit}>
								Update Settings
							</button>
							
							{error && <div className="alert alert-warning" role="alert">{error.message}</div>}
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.ADMIN);
	
/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(AccountBasicFormBase));
   we just organize it like bellow:
*/
const AdminSettingsGeneralForm = compose(
	withAuthorization(condition),
	withFirebase,
)(AdminSettingsGeneral);

export default AdminSettingsGeneralForm;